import { Button } from "react-bootstrap";
import { useAppSelector } from "app/hooks";
import { createCheckoutSession } from "stripe/createCheckoutSession";

export default function Upgrade(props: any) {
	const user = useAppSelector((state) => state.authentication.user);

	return (
		<div
			className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
			style={{ height: "calc(100vh - 52px)" }}
		>
			<h1 className='login-title h3 mt-2 mb-4'>Upgrade your account!</h1>
			<Button
				onClick={() => {
					user && createCheckoutSession(user);
				}}
			>
				Stripe Checkout
				<i className='bi bi-arrow-right ms-2'></i>
			</Button>
		</div>
	);
}
