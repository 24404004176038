import { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PasswordInput = (props) => {
	const { password, setPassword, labelText } = props;

	const [isVisible, setIsVisible] = useState(false);

	return (
		<>
			<div className='form-group mb-3'>
				<label htmlFor='password' className='form-label'>
					{labelText ? labelText : 'Password'}
				</label>
				<div className='input-group'>
					<input
						type={isVisible ? 'text' : 'password'}
						id='password'
						className='form-control'
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						minLength={8}
						required
					/>
					<button
						className='btn btn-outline-primary'
						type='button'
						onClick={(e) => {
							e.preventDefault();
							setIsVisible(!isVisible);
						}}
					>
						<i className={isVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'}></i>
					</button>
				</div>
			</div>
		</>
	);
};

export default PasswordInput;
