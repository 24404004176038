import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const initialState = {
	user: null,
	data: {},
	status: "idle"
};

export const authSlice = createSlice({
	name: "authentication",
	initialState,
	reducers: {
		setAll(state, action) {
			return action.payload;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setData: (state, action) => {
			state.data = action.payload;
		},

		setStatus: (state, action) => {
			state.status = action.payload;
		}
	}
});

export const { setAll, setUser, setData, setStatus } = authSlice.actions;

export default authSlice.reducer;
