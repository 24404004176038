import { Stripe, loadStripe } from "@stripe/stripe-js";

const stripeKey = process.env.REACT_APP_STRIPE_PK || "";

let stripePromise: Stripe | null;

const initialiseStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(stripeKey);
  }

  console.log("Stripe initialised");

  return stripePromise;
};
export default initialiseStripe;
