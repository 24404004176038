import React, { useReducer, useEffect, useState } from "react";
import dayjs from "dayjs";
import Spinner from "../features/common/Spinner";
import Checklist from "features/checklist/checklist";
import { db } from "firebase/db";
import useScreenSize from "helpers/useScreenSize";

import { useSelector, useDispatch } from "react-redux";
import {
	openChecklists,
	removeChecklists
} from "../features/checklist/checklistSlice";
import { setDefaultChecklists } from "../features/checklist/checklistSlice";

const updateLocale = require("dayjs/plugin/updateLocale");
const weekday = require("dayjs/plugin/weekday");
const dayOfYear = require("dayjs/plugin/dayOfYear");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

dayjs.updateLocale("en", {
	weekStart: 1
});

const Home = (props) => {
	const { userData, userId } = props;
	const status = useSelector((state) => state.authentication.status);

	const checklists = useSelector(openChecklists);

	const [templates, setTemplates] = useState([]);
	const [backlogs, setBacklogs] = useState([]);
	const [activePanel, setActivePanel] = useState(0);

	const screenSize = useScreenSize();
	const dispatch = useDispatch();

	const getChecklistTitle = (checklistId) => {
		const titleInfo = checklistId.split("-");
		if (titleInfo.length === 1) {
			return `Choose ${titleInfo[0]}`;
		}

		if (["backlog", "template"].includes(checklistId.split("-")[0])) {
			return titleInfo[0];
		}

		const dateReformatted = titleInfo[1].replace(
			/([0-9]{2})([0-9]{2})([0-9]{4})/g,
			`$3-$1-$2`
		);

		let titleFormat;
		switch (titleInfo[0]) {
			case "daily":
				titleFormat = "DD/MM/YYYY";
				break;
			case "day":
				titleFormat = "DD/MM/YYYY";
				break;
			case "week":
				titleFormat = "DD/MM/YYYY";
				break;
			case "month":
				titleFormat = "MM/YYYY";
				break;
			case "year":
				titleFormat = "YYYY";
				break;
			default:
				titleFormat = "DD/MM/YYYY";
				break;
		}
		// prettier-ignore
		return `${titleInfo[0].charAt(0).toUpperCase()}${titleInfo[0].slice(1)}: ${dayjs(dateReformatted).format(titleFormat)}`;
	};

	useEffect(() => {
		if (userData?.accounts?.default && userId && status === "authenticated") {
			const dbRef = db
				.collection("accounts")
				.doc(userData.accounts.default)
				.collection("users")
				.doc(userId)
				.collection("checklists");

			dbRef
				.where("type", "==", "template")
				.where("status", "==", "live")
				.onSnapshot((querySnapshot) => {
					const loadedTemplates = [];
					querySnapshot.forEach(function (doc) {
						const data = doc.data();
						data.id = doc.id.split("-")[1];
						loadedTemplates.push(data);
					});
					loadedTemplates.length > 0
						? setTemplates(loadedTemplates)
						: setTemplates([]);
				});
		} else {
			setTemplates([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, userId, status]);

	useEffect(() => {
		if (userData?.accounts?.default && userId && status === "authenticated") {
			const dbRef = db
				.collection("accounts")
				.doc(userData.accounts.default)
				.collection("users")
				.doc(userId)
				.collection("checklists");

			dbRef
				.where("type", "==", "backlog")
				.where("status", "==", "live")
				.onSnapshot((querySnapshot) => {
					const loadedBacklogs = [];
					querySnapshot.forEach(function (doc) {
						const data = doc.data();
						data.id = doc.id.split("-")[1];
						loadedBacklogs.push(data);
					});
					loadedBacklogs.length > 0
						? setBacklogs(loadedBacklogs)
						: setBacklogs([]);
				});
		} else {
			setBacklogs([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, userId, status]);

	useEffect(() => {
		if (status === "authenticated") {
			dispatch(setDefaultChecklists());
		} else {
			dispatch(removeChecklists());
		}
	}, [status]);

	return (
		<>
			<div className='checklist-page'>
				<div
					className={`d-flex group-checklist-container ${
						screenSize !== "large" ? "flex-column" : ""
					}`}
				>
					{screenSize !== "large" && checklists.length && (
						<>
							<nav>
								<div className='nav nav-tabs' id='nav-tab' role='tablist'>
									{checklists.map((checklist, index) => {
										return (
											<button
												key={`${checklist}-${index}-tab`}
												className={`nav-link ${
													index === activePanel ? "active" : ""
												}`}
												style={{
													maxWidth: "33%",
													overflow: "hidden",
													whiteSpace: "nowrap",
													display: "inline-block",
													textOverflow: "ellipsis",
													fontSize: "0.9rem"
												}}
												id={`${checklist}-tab`}
												type='button'
												role='tab'
												aria-controls={checklist}
												aria-selected={index === activePanel}
												onClick={() => {
													setActivePanel(index);
												}}
											>
												{getChecklistTitle(checklist)}
											</button>
										);
									})}
								</div>
							</nav>
							<div className='tab-content' id='nav-tabContent'>
								{checklists.map((checklist, index) => {
									return (
										<div
											className={`tab-pane fade show ${
												index === activePanel ? "active" : ""
											}`}
											key={`${checklist}-${index}`}
											id={`${checklist}-panel`}
											role='tabpanel'
											aria-labelledby={`${checklist}-tab`}
										>
											<Checklist
												checklistIndex={index}
												checklistId={checklist}
												userData={userData}
												userId={userId}
												templates={templates}
												backlogs={backlogs}
												checklists={checklists}
											/>
										</div>
									);
								})}
							</div>
						</>
					)}
					{screenSize === "large" &&
						checklists.length &&
						checklists.map((checklist, index) => {
							return (
								<Checklist
									key={`${checklist}-${index}`}
									checklistIndex={index}
									checklistId={checklist}
									userData={userData}
									userId={userId}
									templates={templates}
									backlogs={backlogs}
									checklists={checklists}
								/>
							);
						})}
				</div>
			</div>
		</>
	);
};

export default Home;
