import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const updateLocale = require("dayjs/plugin/updateLocale");
const weekday = require("dayjs/plugin/weekday");
const dayOfYear = require("dayjs/plugin/dayOfYear");
const customParseFormat = require("dayjs/plugin/customParseFormat");
const advancedFormat = require("dayjs/plugin/advancedFormat");

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(dayOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

dayjs.updateLocale("en", {
	weekStart: 1
});

const defaultChecklists = [
	`week-${dayjs().weekday(0).format("MMDDYYYY")}`,
	`daily-${dayjs().format("MMDDYYYY")}`,
	`month-${dayjs().date(1).format("MMDDYYYY")}`
];

const initialState = {
	openChecklists: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
// 	'counter/fetchCount',
// 	async (amount) => {
// 	  const response = await fetchCount(amount);
// 	  // The value we return becomes the `fulfilled` action payload
// 	  return response.data;
// 	}
//   );

export const checklistSlice = createSlice({
	name: "checklists",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		setChecklists: (state, action) => {
			state.openChecklists = [...action.payload];
		},
		setDefaultChecklists: (state) => {
			state.openChecklists = [...defaultChecklists];
		},
		removeChecklists: (state) => {
			state.openChecklists = [];
		}
	}
});

export const { setChecklists, setDefaultChecklists, removeChecklists } =
	checklistSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const openChecklists = (state) => state.checklists.openChecklists;

// We can also write thunks by hand, which may contain both sync and async logic.
export const changeChecklists = (payload) => (dispatch, getState) => {
	const currentChecklists = [...openChecklists(getState())];
	if (payload.value) {
		currentChecklists[payload.index] = payload.value;
	} else {
		currentChecklists.splice([payload.index], 1);
	}
	dispatch(setChecklists(currentChecklists));
};

export const addOpenChecklist = () => (dispatch, getState) => {
	const currentChecklists = [...openChecklists(getState())];
	if (currentChecklists.length < 3) {
		currentChecklists.push(`daily-${dayjs().format("MMDDYYYY")}`);
	}
	dispatch(setChecklists(currentChecklists));
};

export default checklistSlice.reducer;
