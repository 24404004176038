import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function PasswordReset(props) {
	const { auth } = props;
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState({ text: '', status: 'waiting' });

	const sendResetPasswordEmail = () => {
		auth
			.sendPasswordResetEmail(email)
			.then(() => {
				setEmail('');
				setMessage({ text: 'Password email sent!', status: 'success' });
			})
			.catch((error) => {
				setEmail('');
				setMessage({
					text: "Doesn't look like that email is registered as an account!",
					status: 'warning'
				});
			});
	};

	return (
		<div
			className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
			style={{ height: 'calc(100vh - 52px)' }}
		>
			<h1 className='login-title h3 mt-2 mb-4'>Reset Password</h1>
			<p className='login-description'>
				Enter your account email address and we'll send you an email with
				further details on how to reset your password.
			</p>
			<form
				className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
				onSubmit={(e) => {
					e.preventDefault();
					sendResetPasswordEmail();
				}}
				aria-describedby='form_error'
			>
				<div className='form-group mb-3'>
					<label htmlFor='emailAddress' className='form-label'>
						Email address
					</label>
					<input
						className='form-control'
						aria-describedby='emailHelp'
						placeholder='eg: john@dailychecklist.com.au'
						type='email'
						id='emailAddress'
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						autoFocus={true}
						required
					/>
				</div>
				<button type='submit' className='btn btn-primary col-12 mb-3'>
					Submit
				</button>
				{message.status !== 'waiting' && (
					<div className={`alert alert-${message.status}`} role='alert'>
						{message.text}
					</div>
				)}
			</form>
			<p className='new-user'>
				Go back to the <Link to='/login'>login page</Link>
			</p>
		</div>
	);
}
