import { db } from "../firebase/firebase";
import getStripe from "./initialiseStripe";
import { getGuid } from "helpers";

const priceId = process.env.REACT_APP_STRIPE_PRODUCT_PRICE_ID || "";

export async function createCheckoutSession(uid: string) {
  console.log("Creating checkout session: ", uid);

  const initialiseStripe = await getStripe().then((stripe) => {
    console.log("Stripe initialised");
    return stripe;
  });

  const newKey = getGuid();

  const sessions = db
    .collection("customers")
    .doc(uid)
    .collection("checkout_sessions");

  // Create a new checkout session in the subollection inside this users document
  await sessions
    .doc(newKey)
    .set({
      price: priceId,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    })
    .then(() => {
      sessions.doc(newKey).onSnapshot(async (snap) => {
        const data = snap.data();

        console.log("Checkout session snapshot: ", data);

        if (data) {
          const { sessionId } = data;
          if (sessionId) {
            // We have a session, let's redirect to Checkout
            // Init Stripe
            const stripe = await getStripe();

            if (stripe) {
              stripe.redirectToCheckout({ sessionId });
            }
          }
        }
      });
    });

  console.log("Checkout session created: ", newKey);

  // Wait for the CheckoutSession to get attached by the extension
}
