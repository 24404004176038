import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/functions";

// Your app's Firebase configuration
const firebaseConfig: object = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

const auth: firebase.auth.Auth = firebase.auth();
const db: firebase.firestore.Firestore = firebase.firestore();
const functions: firebase.functions.Functions = firebase.functions();
const messaging: firebase.messaging.Messaging = firebase.messaging();

if (
	process.env.NODE_ENV === "development" &&
	process.env.REACT_APP_EMULATORS === "true"
) {
	auth.useEmulator("http://localhost:9099");
	db.useEmulator("localhost", 8080);
	functions.useEmulator("localhost", 5001);
}

export { auth, db, functions, messaging };

export default firebase;
