import { useState } from "react";
import { Link } from "react-router-dom";
import PasswordInput from "features/authentication/password-input";
import { useAuthentication } from "../firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { setStatus } from "features/authentication/authSlice";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { loginUser } = useAuthentication();
	const dispatch = useDispatch();

	const handleSubmit = () => {
		loginUser(email, password)
			.then(() => {
				dispatch(setStatus("authenticated"));
			})
			.catch((error) => {
				dispatch(setStatus("error"));
				console.log(error);
			});
	};

	return (
		<>
			<div
				className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
				style={{ height: "calc(100vh - 52px)" }}
			>
				<img
					className='login-logo mb-3 col-8 col-sm-5 col-md-3 mx-auto'
					alt='Daily Checklist'
					src='/images/daily-checklist-logo.png'
				/>
				<h1 className='login-title h3 mt-2 mb-4'>User Login</h1>
				<form
					className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					aria-describedby='form_error'
				>
					<div className='form-group mb-3'>
						<label htmlFor='emailAddress' className='form-label'>
							Email address
						</label>
						<input
							className='form-control'
							aria-describedby='emailHelp'
							placeholder='eg: john@dailychecklist.com.au'
							type='email'
							id='emailAddress'
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							autoFocus={true}
							required
						/>
					</div>
					<PasswordInput password={password} setPassword={setPassword} />

					<button type='submit' className='btn btn-primary col-12 mb-3'>
						Submit
					</button>
				</form>
				<p className='new-user'>
					Forgot your password? <Link to='/password-reset'>Reset password</Link>
				</p>
				<p className='new-user'>
					New user? <Link to='/register'>Register new account</Link>
				</p>
			</div>
		</>
	);
};

export default Login;
