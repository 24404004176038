import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthentication } from "../../firebase/auth";
import { Dropdown, Button } from "react-bootstrap";
import { usePremiumStatus } from "stripe/usePremiumStatus";
import { createCheckoutSession } from "stripe/createCheckoutSession";
import { useDispatch, useSelector } from "react-redux";
import { addOpenChecklist } from "../checklist/checklistSlice";
import { TimerModal } from "../timer";
import { setShow } from "../timer/timerSlice";
import { auth, functions } from "../../firebase/firebase";

export default function Navigation(props) {
  const user = useSelector((state) => state.authentication.user);
  const disableAddChecklist = useSelector(
    (state) => state.checklists?.openChecklists?.length === 3
  );
  const dispatch = useDispatch();
  const { logoutUser } = useAuthentication();
  const userIsPremium = usePremiumStatus();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (
        user &&
        (Date.parse(user.metadata.creationTime) >= new Date("2023-5-15") ||
          (!userIsPremium && location.pathname !== "/upgrade"))
      ) {
        // route to upgrade page using react router
        history.push("/upgrade");
      } else if (user && userIsPremium && location.pathname === "/upgrade") {
        history.push("/");
      }
    });
  }, [history, userIsPremium, location]);

  return (
    <>
      <nav className="navbar navbar-light fixed-top border-bottom border-1 bg-white pt-1 pb-1">
        <div className="d-flex justify-content-between vw-100 ps-3 pe-3">
          <Link className="navbar-brand" to="/">
            <img
              src="/images/daily-checklist-logo-250px.png"
              alt="Daily Checklist"
              width="110px"
            />
          </Link>
          <div className="ms-auto d-flex justify-content-between align-items-center">
            {user ? (
              <nav className="nav">
                <Button
                  variant="white"
                  className="py-0"
                  style={{ paddingLeft: 7, paddingRight: 6 }}
                  onClick={() => {
                    dispatch(setShow(true));
                  }}
                >
                  <i className="bi-play" style={{ fontSize: "1.5rem" }}></i>
                </Button>
                <Dropdown className="navigation-menu" align="end">
                  <Dropdown.Toggle variant="white">
                    <i className="bi-list" style={{ fontSize: "1.5rem" }}></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={disableAddChecklist}
                      onClick={() => {
                        dispatch(addOpenChecklist());
                      }}
                    >
                      Add Checklist
                    </Dropdown.Item>

                    {userIsPremium ? (
                      <Dropdown.Item
                        // TODO Enable this feature when ready!

                        onClick={() => {
                          const functionRef = functions.httpsCallable(
                            "ext-firestore-stripe-payments-createPortalLink"
                          );
                          const { data } = functionRef({
                            returnUrl: window.location.origin,
                          }).then((data) => {
                            console.log("data", data);
                            window.location.replace(data.data.url);
                          });
                        }}
                      >
                        Manage Account
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        // disabled
                        onClick={() => {
                          createCheckoutSession(user);
                        }}
                      >
                        Upgrade To Premium!
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item as={Link} to={"/password-change"}>
                      Change Password
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => {
                        logoutUser();
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </nav>
            ) : null}
          </div>
        </div>
      </nav>
      <TimerModal />
    </>
  );
}
