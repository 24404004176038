const LoadingSpinner = (props) => {
	return (
		<div
			style={{ display: 'flex', flexDirection: 'column', marginTop: '60px' }}
		>
			<div
				className='spinning-loader'
				// style={props.small ? { height: '35px', width: '35px' } : {}}
			/>
			<p
				style={{
					textAlign: 'center',
					color: props.light ? '#ffffff' : '#cccccc'
				}}
			>
				Loading...
			</p>
			<style>{`
				.spinning-loader {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					background: transparent;
					animation: rotate-s-loader 1s linear infinite;
					margin: 10px auto 5px auto;
					border: 5px solid ${!props.light ? '#c3c3c3' : '#737373'};
					border-left-color: ${'gray'};
				}

				@keyframes rotate-s-loader {
					from {
						transform: rotate(0);
					}
					to {
						transform: rotate(360deg);
					}
				}
			`}</style>
		</div>
	);
};

export default LoadingSpinner;
