import { useState, useEffect } from "react";
import { auth } from "../firebase/firebase";

async function isUserPremium(): Promise<boolean> {
    await auth.currentUser?.getIdToken(true);
    const decodedToken = await auth.currentUser?.getIdTokenResult();
    console.log("decodedToken", decodedToken);

    const stripeRole = decodedToken?.claims?.stripeRole === "premium";
    const oldUser = decodedToken?.claims?.oldUser === true;

    return oldUser || stripeRole
        ? true
        : false;
}

export function usePremiumStatus() {
    const user = auth.currentUser;
    const [premiumStatus, setPremiumStatus] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            console.log(user.uid);
            const checkPremiumStatus = async function () {
                setPremiumStatus(await isUserPremium());
            };
            checkPremiumStatus();
        }
    }, [user]);

    return premiumStatus;
}
