import { useDispatch } from 'react-redux';
import { setSettings, setShow } from '../timer/timerSlice';

export const useTimer = () => {
	const dispatch = useDispatch();

	const updateTimerSettings = (settings) => {
		dispatch(setSettings(settings));
	};

	const updateTimerShow = (show) => {
		dispatch(setShow(show));
	};

	return {
		updateTimerSettings,
		updateTimerShow
	};
};
