import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
// add persistant storage
import { PersistGate } from "redux-persist/integration/react";

// add persist store
import { persistStore } from "redux-persist";
import { Suspense } from "react";

// create persist store
const persistor = persistStore(store);

// Removes all calls to the console when running in production
// if (process.env.NODE_ENV !== "development") {
// 	// eslint-disable-next-line no-console
// 	// console.log = function () {};
// }

console.log("process.env.NODE_ENV", process.env.NODE_ENV);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Suspense fallback={<div />}>
					<App />
				</Suspense>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
