import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PasswordInput from "features/authentication/password-input";
import { useAuthentication } from "../firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { setStatus } from "features/authentication/authSlice";
import { Button, Row, Col } from "react-bootstrap";
import { usePremiumStatus } from "stripe/usePremiumStatus";
import { createCheckoutSession } from "stripe/createCheckoutSession";

const Register = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [message, setMessage] = useState({ text: "", status: "waiting" });

	const [validated, setValidated] = useState(false);
	const [creatingUser, setCreatingUser] = useState(false);

	const dispatch = useDispatch();
	const { createUser, registerUser } = useAuthentication();
	const userIsPremium = usePremiumStatus();

	const handleSubmit = () => {
		if (creatingUser) {
			console.log("We're already creating a user!");
		} else if (
			email.length &&
			firstName.length &&
			lastName.length &&
			password.length
		) {
			dispatch(setStatus("registering"));
			createUser(email, password)
				.then((user) => {
					if (user) {
						createCheckoutSession(user.user.uid);
					}
				})
				.catch((error) => {
					setMessage(error.message);
					dispatch(setStatus("error"));
				});
		} else {
			setMessage({
				text: "need to complete the form properly before submitting!",
				status: "warning"
			});
		}
	};

	useEffect(() => {
		if (userIsPremium) {
			setCreatingUser(true);
		}
	}, [userIsPremium]);

	useEffect(() => {
		if (creatingUser && email.length && password.length) {
			startRegistration();
			dispatch(setStatus("registering"));
			console.log("registering user");
		}
		function startRegistration() {
			registerUser(email, password, firstName, lastName)
				.then(() => {
					dispatch(setStatus("authenticated"));
				})
				.catch((error) => {
					setMessage(error.message);

					dispatch(setStatus("error"));
				});
		}
	}, [creatingUser, email, password]);

	useEffect(() => {
		const passwordRegex =
			/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;
		const valid = {
			email: email.length > 0,
			password: passwordRegex.test(password),
			firstName: firstName.length > 0,
			lastName: lastName.length > 0
		};
		setValidated(Object.values(valid).every(Boolean));
	}, [email, password, firstName, lastName]);

	return (
		<>
			<div
				className='authentication col-12 d-flex flex-column justify-content-center align-items-center'
				style={{ height: "calc(100vh - 52px)" }}
			>
				<img
					className='login-logo mb-3 col-8 col-sm-5 col-md-3 mx-auto'
					alt='Daily Checklist'
					src='/images/daily-checklist-logo.png'
				/>
				<h1 className='login-title h3 mt-2 mb-4'>Register New Account</h1>
				<form
					className='col-lg-6 col-xl-4 col-md-9 col-12 px-3'
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					aria-describedby='form_error'
				>
					<fieldset disabled={creatingUser}>
						<Row>
							<Col md={6}>
								<div className='form-group mb-3'>
									<label htmlFor='firstName' className='form-label'>
										First Name
									</label>
									<input
										className='form-control'
										aria-describedby='firstName'
										placeholder='eg: Joe'
										type='text'
										id='firstName'
										value={firstName}
										minLength={1}
										onChange={(e) => {
											setFirstName(e.target.value);
										}}
										autoFocus={true}
										required
									/>
								</div>
							</Col>
							<Col md={6}>
								<div className='form-group mb-3'>
									<label htmlFor='lastName' className='form-label'>
										Last Name
									</label>
									<input
										className='form-control'
										aria-describedby='firstName'
										placeholder='eg: Citizen'
										type='text'
										id='lastName'
										value={lastName}
										minLength={1}
										onChange={(e) => {
											setLastName(e.target.value);
										}}
										required
									/>
								</div>
							</Col>
						</Row>

						<div className='form-group mb-3'>
							<label htmlFor='emailAddress' className='form-label'>
								Email address
							</label>
							<input
								className='form-control'
								aria-describedby='emailHelp'
								placeholder='eg: john@dailychecklist.com.au'
								type='email'
								id='emailAddress'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								required
							/>
						</div>
						<PasswordInput password={password} setPassword={setPassword} />
						<Button
							size='lg'
							type='submit'
							className='col-12 my-3'
							disabled={creatingUser || !validated}
						>
							Go to Payment
						</Button>
						{message.status !== "waiting" && (
							<div className={`alert alert-${message.status}`} role='alert'>
								{message.text}
							</div>
						)}
					</fieldset>
				</form>

				<p className='new-user'>
					Already have an account? <Link to='/login'>Login here</Link>
				</p>
			</div>
		</>
	);
};

export default Register;
