// utils/useDeviceDetect.js
import React from 'react';

export default function useDeviceDetect() {
	const [isTouch, setIsTouch] = React.useState(false);

	React.useEffect(() => {
		const userAgent =
			typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
		const touchDevice = Boolean(
			userAgent.match(
				/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
			)
		);
		setIsTouch(touchDevice);
	}, []);

	return { isTouch };
}
