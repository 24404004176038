import React, { useState } from "react";
import { Button, Modal, Container, Form } from "react-bootstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTimer } from "./hooks";
import useSound from "use-sound";
import timerSound from "sounds/boop.mp3";
import { useWakeLock } from "react-screen-wake-lock";

const presets = [
	{
		name: "15 minutes",
		cycles: 1,
		work: 15 * 60,
		rest: 0
	},
	{
		name: "30 minutes",
		cycles: 1,
		work: 30 * 60,
		rest: 0
	},
	{
		name: "45 minutes",
		cycles: 1,
		work: 45 * 60,
		rest: 0
	},
	{
		name: "1 hour",
		cycles: 1,
		work: 60 * 60,
		rest: 0
	},
	{
		name: "Tabata - 15 minutes",
		cycles: 15,
		work: 40,
		rest: 20
	}
];
export const TimerModal = (props) => {
	const show = useSelector((state) => state.timer.show);
	const settings = useSelector((state) => state.timer.settings);

	const { updateTimerSettings, updateTimerShow } = useTimer();

	const [size, setSize] = useState(260);

	const [timerRunning, setTimerRunning] = useState(false);

	const [tick, setTick] = useState(0);
	// set interval
	const [status, setStatus] = useState({
		cycles: 0,
		work: settings.work,
		rest: settings.rest
	});

	const [intervalId, setIntervalId] = useState(0);

	const [playbackRate, setPlaybackRate] = useState(1);
	const [play] = useSound(timerSound, { playbackRate });
	const [muteSound, setMuteSound] = useState(false);

	const { isSupported, released, request, release } = useWakeLock({
		onRequest: () => console.log("Screen Wake Lock: requested!"),
		onError: () => console.log("An error happened 💥"),
		onRelease: () => console.log("Screen Wake Lock: released!")
	});

	function playSound(interval, cycles, pitch) {
		if (muteSound) {
			return;
		}
		setPlaybackRate(pitch);
		for (var i = 0; i < cycles; i++) {
			setTimeout(() => {
				play();
			}, i * interval);
		}
	}

	const close = () => {
		updateTimerShow(false);
	};

	const resetTimer = () => {
		setTimerRunning(false);
		if (intervalId) {
			clearInterval(intervalId);
			setIntervalId(0);
		}
		setStatus({
			cycles: 0,
			work: settings.work,
			rest: settings.rest
		});
		release();
	};

	const startTimer = () => {
		settings.work !== status.work && playSound(1000, 1, 1);
		const newIntervalId = setInterval(() => {
			setTick((tick) => tick + 1);
		}, 1000);
		setIntervalId(newIntervalId);
		setTimerRunning(true);
		request();
	};

	const stopTimer = () => {
		playSound(125, 3, 4);
		clearInterval(intervalId);
		setIntervalId(0);
		setTimerRunning(false);
		release();
	};

	useEffect(() => {
		if (status.cycles === settings.cycles) {
			resetTimer();
		}

		if (status.work === 4 && timerRunning) {
			playSound(1000, 4, 1);
		}

		if (status.rest === 4 && timerRunning) {
			playSound(1000, 4, 1.5);
		}

		if (status.work === 0 && status.rest === settings.rest && timerRunning) {
			playSound(125, 3, 4);
		}

		if (status.work === settings.work && timerRunning) {
			playSound(1000, 1, 1);
		}

		console.log(status);
	}, [status, timerRunning]);

	useEffect(() => {
		resetTimer();

		console.log(settings);
	}, [settings]);

	useEffect(() => {
		return () => {
			resetTimer();
		};
	}, [show]);

	useEffect(() => {
		if (timerRunning) {
			setStatus((t) => {
				if (t.cycles !== settings.cycles) {
					if (t.work > 0) {
						return { ...t, work: t.work - 1 };
					} else if (t.rest > 1) {
						return { ...t, rest: t.rest - 1 };
					} else {
						return {
							cycles: t.cycles + 1,
							work: settings.work,
							rest: settings.rest
						};
					}
				} else {
					return {
						cycles: settings.cycles,
						work: settings.work,
						rest: settings.rest
					};
				}
			});
		}
	}, [tick]);

	return (
		<>
			<Modal
				show={show}
				onHide={() => {
					close();
				}}
				id='timer-modal'
				centered
				size='md'
				fullscreen='md-down'
				aria-labelledby='contained-modal-title-vcenter'
				className='modal-dialog-centered'
			>
				<Modal.Header className='modal-header' closeButton>
					<Modal.Title
						id='contained-modal-title-vcenter'
						className='modal-title'
					>
						Countdown Timer
					</Modal.Title>
					<Button
						variant='primary'
						className='ms-3'
						onClick={() => {
							setMuteSound(!muteSound);
						}}
					>
						<i className={`bi-volume-${muteSound ? "mute" : "up"}-fill`}></i>
					</Button>
				</Modal.Header>
				<Modal.Body className='d-flex flex-column justify-content-center modal-body'>
					{/* <Container>
                <Form className=''>
                    <Container className='d-flex gap-3 mx-0 px-0'>
                        <Form.Group className='flex-grow-1'>
                            <Form.Label className='text-white'>Cycles</Form.Label>
                            <Form.Control
                                type='number'
                                value={settings.cycles}
                                onChange={(e) => {
                                    setSettings({
                                        ...settings,
                                        cycles: e.target.valueAsNumber
                                    });
                                }}
                                min='1'
                                max='30'
                                step='1'
                                placeholder='Cycles'
                            />
                        </Form.Group>
                        <Form.Group className='flex-grow-1'>
                            <Form.Label className='text-white'>Work</Form.Label>
                            <Form.Control
                                type='number'
                                value={settings.work}
                                onChange={(e) => {
                                    setSettings({
                                        ...settings,
                                        work: e.target.valueAsNumber
                                    });
                                }}
                                min='1'
                                max='30'
                                step='1'
                                placeholder='Work'
                            />
                        </Form.Group>
                        <Form.Group className='flex-grow-1'>
                            <Form.Label className='text-white'>Rest</Form.Label>
                            <Form.Control
                                type='number'
                                value={settings.rest}
                                onChange={(e) => {
                                    setSettings({
                                        ...settings,
                                        rest: e.target.valueAsNumber
                                    });
                                }}
                                min='1'
                                max='30'
                                step='1'
                                placeholder='Rest'
                            />
                        </Form.Group>
                    </Container>
                </Form>
            </Container> */}
					<Container className=''>
						<div
							className='timer-circle-outer d-flex flex-column justify-content-center align-items-center rounded-circle p-3 mx-auto mb-4'
							style={{}}
						>
							<div
								className='timer-circle-inner text-white d-flex flex-column justify-content-center align-items-center rounded-circle mx-auto my-auto position-relative'
								style={{
									width: size,
									height: size
								}}
							>
								<svg
									version='1.1'
									xmlns='http://www.w3.org/2000/svg'
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
										zIndex: 9998
									}}
									className='rest-circle'
								>
									<defs>
										<linearGradient
											id='linear'
											x1='0%'
											y1='0%'
											x2='100%'
											y2='0%'
											gradientTransform='rotate(-15)'
										>
											<stop offset='0%' className='main' />
											<stop offset='100%' className='alt' />
										</linearGradient>
									</defs>
									<circle
										cx={size / 2}
										cy={size / 2}
										r={size / 2 - 20}
										stroke='url(#linear)'
										transform={`rotate(-90, ${size / 2}, ${size / 2})`}
										style={{
											strokeWidth: 6,
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeDasharray: 2 * Math.PI * (size / 2 - 20),
											strokeDashoffset:
												2 * Math.PI * (size / 2 - 20) -
												2 *
													Math.PI *
													(size / 2 - 20) *
													(((timerRunning && status.rest !== 0 ? 1 : 0) +
														settings.rest -
														status.rest +
														settings.work -
														status.work) /
														(settings.work + settings.rest)),
											fill: "transparent",
											transitionDuration: "1s",
											transitionDelay: "0s",
											transitionTimingFunction: "linear"
										}}
									/>
								</svg>
								<svg
									version='1.1'
									xmlns='http://www.w3.org/2000/svg'
									style={{
										position: "absolute",
										top: 0,
										left: 0,
										width: "100%",
										height: "100%",
										zIndex: 9999
									}}
									className='work-circle'
								>
									<defs>
										<linearGradient
											id='linearWork'
											x1='0%'
											y1='0%'
											x2='100%'
											y2='0%'
											gradientTransform='rotate(-15)'
										>
											<stop offset='0%' className='main' />
											<stop offset='100%' className='alt' />
										</linearGradient>
									</defs>
									<circle
										cx={size / 2}
										cy={size / 2}
										r={size / 2 - 20}
										stroke='url(#linearWork)'
										transform={`rotate(-90, ${size / 2}, ${size / 2})`}
										style={{
											strokeWidth: 6,
											strokeLinecap: "round",
											strokeLinejoin: "round",
											strokeDasharray: 2 * Math.PI * (size / 2 - 20),
											strokeDashoffset:
												2 * Math.PI * (size / 2 - 20) -
												2 *
													Math.PI *
													(size / 2 - 20) *
													(((timerRunning && status.work !== 0 ? 1 : 0) +
														settings.work -
														status.work) /
														(settings.work + settings.rest)),
											fill: "transparent",
											transitionDuration: "1s",
											transitionDelay: "0s",
											transitionTimingFunction: "linear"
										}}
									/>
								</svg>

								<h3 className='text-center'>{status.work ? "Work" : "Rest"}</h3>
								<h3 className='text-center'>
									{new Date((status.work ? status.work : status.rest) * 1000)
										.toISOString()
										.slice(11, 19)}
								</h3>
								{status.cycles !== settings.cycles ? (
									<h6>
										Phase: {status.cycles + 1} / {settings.cycles}
									</h6>
								) : (
									<h6 className='text-success fw-bold'>All done!</h6>
								)}
							</div>
						</div>
					</Container>
					<Container>
						<Form className='mx-2'>
							<Form.Group className='mb-3 d-flex flex-column justify-content-center '>
								<Form.Label className='visually-hidden'>Presets</Form.Label>
								<Form.Select
									className=''
									onChange={(event) => {
										updateTimerSettings(presets[event.target.value]);
									}}
								>
									{presets.map((preset, index) => (
										<option key={preset.name} value={index}>
											{preset.name}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Form>
						{/* Start/stop timer */}
						<div className='d-flex justify-content-center gap-3 mx-2'>
							<Button
								variant='primary'
								className='py-2 px-4 w-50'
								onClick={() => {
									timerRunning || intervalId ? stopTimer() : startTimer();
								}}
							>
								{timerRunning ? "Stop" : "Start"}
							</Button>
							{/* Reset timer button */}
							<Button
								variant='secondary'
								className='py-2 px-4 w-50'
								onClick={() => {
									resetTimer();
								}}
							>
								Reset
							</Button>
						</div>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};
