import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const initialState = {
	settings: {
		work: 60,
		rest: 30,
		cycles: 4
	},
	show: false
};

export const timerSlice = createSlice({
	name: 'timer',
	initialState,
	reducers: {
		setSettings: (state, action) => {
			state.settings = { ...action.payload };
		},
		setShow: (state, action) => {
			state.show = action.payload;
		}
	}
});

export const { setSettings, setTimer, setShow } = timerSlice.actions;

export default timerSlice.reducer;
